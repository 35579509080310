<div class="wrapper auth-layout">
  <div class="container main-container d-flex flex-column justify-content-center">
    <div class="row">
      <div class="col text-center">
        <img src="assets/images/groomoteka-text-white.svg" alt="Groomoteka logo" class="logo" />
      </div>
    </div>
    <router-outlet></router-outlet>
  </div>
  <app-footer [layout]="'authLayout'"></app-footer>
</div>
