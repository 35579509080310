<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    #drawer
    class="sidenav"
    fixedInViewport
    [attr.role]="(isHandset$ | async) ? 'navigation' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="(isHandset$ | async) === false"
  >
    <mat-toolbar-row>
      <img src="assets/images/groomoteka-sygnet.svg" alt="Groomoteka" class="logo" />
      <p *ngIf="userName">{{ userName }}</p>
      <p *ngIf="salonName" class="salon-name">{{ salonName }}</p>
      <p
        *ngIf="user && !user.is_confirmed"
        class="text-center text-danger"
        [innerHTML]="'CORE.NAVIGATION.CONFIRM-ACCOUNT-REMINDER' | translate: { date: confirmationExpiryDateLocalized }"
      ></p>
      <a href="#" (click)="logout(); (false)">{{ 'CORE.NAVIGATION.LOGOUT' | translate }}</a>
    </mat-toolbar-row>
    <mat-nav-list>
      <hr />
      <a mat-list-item [routerLink]="['/portal']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <mat-icon aria-hidden="false" aria-label="calendar icon">calendar_today</mat-icon>
        {{ 'CORE.NAVIGATION.CALENDAR' | translate }}
      </a>
      <a
        mat-list-item
        [routerLink]="['/portal/clients']"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <mat-icon aria-hidden="false" aria-label="supervisor_account icon">supervisor_account</mat-icon>
        {{ 'CORE.NAVIGATION.CLIENTS' | translate }}
      </a>
      <a
        mat-list-item
        [routerLink]="['/portal/history']"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <mat-icon aria-hidden="false" aria-label="schedule icon">schedule</mat-icon>
        {{ 'CORE.NAVIGATION.HISTORY' | translate }}
      </a>
      <hr />
      <a
        mat-list-item
        [routerLink]="['/administration/services']"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <mat-icon aria-hidden="false" aria-label="content_cut icon">content_cut</mat-icon>
        {{ 'CORE.NAVIGATION.SERVICES' | translate }}
      </a>

      <a
        mat-list-item
        [routerLink]="['/administration']"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        <mat-icon aria-hidden="false" aria-label="settings icon">settings</mat-icon>
        {{ 'CORE.NAVIGATION.SETTINGS' | translate }}
      </a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="wrapper authenticated-layout">
      <mat-toolbar color="primary">
        <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()">
          <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
        </button>
        <span>{{ 'CORE.COMMON.PAGE-NAME' | translate }}</span>
      </mat-toolbar>
      <div class="container-fluid main-container my-3">
        <router-outlet></router-outlet>
      </div>
      <app-footer></app-footer>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
