<footer class="footer" [ngClass]="{ 'auth-layout': layout }">
  {{ 'CORE.COMMON.PAGE-NAME' | translate }} {{ currentYear }}

  <a
    href="#"
    title="Change language"
    *ngIf="currentLangIsPl()"
    (click)="useLanguage('en'); (false)"
    class="ml-1"
    [ngClass]="{ white: layout }"
  >
    {{ 'CORE.COMMON.LANGUAGE-LABELS.ENGLISH' | translate }}</a
  >
  <a
    href="#"
    title="Change language"
    *ngIf="currentLangIsEn()"
    (click)="useLanguage('pl'); (false)"
    class="ml-1"
    [ngClass]="{ white: layout }"
  >
    {{ 'CORE.COMMON.LANGUAGE-LABELS.POLISH' | translate }}</a
  >
</footer>
