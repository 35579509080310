import { Component } from '@angular/core';
import { SeoTagType } from '../enums';
import { SEOService } from '../services/seo.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent {
  constructor(private seoService: SEOService) {
    this.seoService.setSEO([
      {
        tagType: SeoTagType.title,
        i18nKey: 'CORE.DEFAULT-META.404-META-TITLE',
      },
    ]);
  }
}
