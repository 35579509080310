import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

export class LanguageCustomLoader implements TranslateLoader {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  public getTranslation(lang: string): Observable<any> {
    // Don't use paths array and foreach for file loading automatization.
    // There is probably some kind of racing and require() doesnt work properly inside loops.
    const administrationModuleJson = require(`./app/administration/i18n/${lang}.json`);
    const authModuleJson = require(`./app/auth/i18n/${lang}.json`);
    const clientModuleJson = require(`./app/client/i18n/${lang}.json`);
    const coreModuleJson = require(`./app/core/i18n/${lang}.json`);

    const jsonArray = [administrationModuleJson, authModuleJson, clientModuleJson, coreModuleJson];

    let completeI18n = {};
    jsonArray.forEach((jsonFile) => {
      completeI18n = { ...jsonFile, ...completeI18n };
    });

    return of(completeI18n);
  }
}
