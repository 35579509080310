import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    public oauthService: OAuthService,
    private router: Router
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.oauthService.getAccessToken()}`,
      },
    });

    return next.handle(request).pipe(
      tap(
        () => {},
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            // eslint-disable-next-line no-magic-numbers
            if (err.status !== 401) {
              return;
            }

            this.oauthService.logOut();
            this.router.navigate(['/auth/login']);
          }
        }
      )
    );
  }
}
