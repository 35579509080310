import * as Rollbar from 'rollbar'; // When using Typescript < 3.6.0.
// `import Rollbar from 'rollbar';` is the required syntax for Typescript 3.6.x.
// However, it will only work when setting either `allowSyntheticDefaultImports`
// or `esModuleInterop` in your Typescript options.

import { Injectable, Inject, InjectionToken, ErrorHandler } from '@angular/core';
import { environment } from '@environment/environment';
import { version } from '@environment/version';

const rollbarConfig = {
  enabled: environment.production,
  accessToken: environment.rollbarAccessToken,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: environment.gitlabEnv,
    client: {
      javascript: {
        // The version number cannot contain any whitespace or it will break
        code_version: version.revision.trim(),
        source_map_enabled: true,
        guess_uncaught_frames: true
      }
    }
  },
};

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(
    @Inject(RollbarService) private rollbar: Rollbar
  ) { }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public handleError(err: any): void {
    console.error(err.originalError || err);
    this.rollbar.error(err.originalError || err);
  }
}

export function rollbarFactory(): Rollbar {
  return new Rollbar(rollbarConfig);
}
