<div class="wrapper basic-layout">
  <div class="container main-container d-flex flex-column justify-content-center">
    <div class="row">
      <div class="col text-center">
        <img src="assets/images/groomoteka-text-white.svg" alt="Groomoteka logo" class="logo" />
      </div>
    </div>
    <div class="row">
      <div class="col text-center">
        <h1>404</h1>
        <p class="mb-4">{{ 'CORE.COMMON.HEADINGS.404' | translate }}</p>
        <a [routerLink]="['/']" class="white">{{ 'CORE.COMMON.ACTIONS.BACK-TO-HOMEPAGE' | translate }}</a>
      </div>
    </div>
  </div>
  <app-footer [layout]="'basicLayout'"></app-footer>
</div>
