import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import * as _ from 'lodash';
import { SeoTagType } from '../enums/seo-tag-type.enum';
import { ISeoTagType } from '../models';

@Injectable({
  providedIn: 'root',
})
export class SEOService {
  private currentSeoTags: ISeoTagType[] = [];
  private readonly allDefaultSeoTags: ISeoTagType[] = [
    {
      tagType: SeoTagType.title,
      i18nKey: 'CORE.DEFAULT-META.META-TITLE',
    },
    {
      tagType: SeoTagType.description,
      i18nKey: 'CORE.DEFAULT-META.META-DESCRIPTION',
    },
  ];

  constructor(private title: Title, private meta: Meta, private translate: TranslateService) {
    this.setSEO(this.allDefaultSeoTags);
    this.listenToLanguageChange();
    this.setImage();
  }

  public setSEO(seoTags: ISeoTagType[]): void {
    this.prepCurrentSeoTags(seoTags);
    const metaTitle = this.currentSeoTags[0];
    const metaDescription = this.currentSeoTags[1];
    forkJoin(
      this.translate.get(metaTitle.i18nKey, metaTitle.i18nParams ? metaTitle.i18nParams : null),
      this.translate.get(metaDescription.i18nKey, metaDescription.i18nParams ? metaDescription.i18nParams : null)
    ).subscribe(([title, description]) => {
      this.updateTitle(title);
      this.updateDescription(description);
    });
  }

  private prepCurrentSeoTags(seoTags: ISeoTagType[]): void {
    this.currentSeoTags = _.cloneDeep(this.allDefaultSeoTags);
    seoTags.forEach((seoTag) => {
      const tag = this.currentSeoTags.find((currentSeoTag) => currentSeoTag.tagType === seoTag.tagType);
      tag.i18nKey = seoTag.i18nKey;
      if (seoTag.i18nParams) {
        tag.i18nParams = seoTag.i18nParams;
      }
    });
  }

  private listenToLanguageChange(): void {
    this.translate.onLangChange.subscribe(() => {
      this.setSEO(this.currentSeoTags);
    });
  }

  private updateTitle(title: string): void {
    this.title.setTitle(title);
  }

  private updateDescription(desc: string): void {
    this.meta.updateTag({ name: 'description', content: desc });
  }

  private setImage(): void {
    const baseUrl = window.location.protocol + '//' + window.location.hostname;
    const imageUrl = baseUrl + '/assets/images/groomoteka-share-fb.jpg';

    this.meta.updateTag({ property: 'og:image', content: imageUrl });
  }
}
