import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthLayoutComponent } from './auth/auth-layout/auth-layout.component';
import { AuthenticatedLayoutComponent } from './shared/authenticated-layout/authenticated-layout.component';
import { AuthGuard } from './auth/services/auth-guard.service';
import { IsSignInGuard } from './auth/services/is-sign-in.guard.service';
import { NotFoundComponent } from './core/not-found/not-found.component';

const routes: Routes = [
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/auth/login',
      },
    ],
  },
  {
    path: 'auth',
    component: AuthLayoutComponent,
    canActivate: [IsSignInGuard],
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    // authenticated user, needs canLoad guard
    path: 'portal',
    canActivate: [AuthGuard],
    loadChildren: () => import('./client/client.module').then((m) => m.ClientModule),
  },
  {
    path: 'administration',
    component: AuthenticatedLayoutComponent,
    canActivate: [AuthGuard],
    loadChildren: () => import('./administration/administration.module').then((m) => m.AdministrationModule),
  },
  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '/404' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
