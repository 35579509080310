import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, LOCALE_ID } from '@angular/core';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { TranslateModule, TranslateService, TranslateLoader } from '@ngx-translate/core';
import { SupportedLanguagesCodes } from './core/enums';
import { LanguageCustomLoader } from 'language-custom-loader';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { AuthGuard } from './auth/services/auth-guard.service';
import { TokenInterceptor } from './core/interceptors/token.interceptor';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RollbarService, rollbarFactory, RollbarErrorHandler } from './rollbar';
import * as moment from 'moment';
import localePl from '@angular/common/locales/pl';
import localeEn from '@angular/common/locales/en';
import localePlExtra from '@angular/common/locales/extra/pl';
import { registerLocaleData } from '@angular/common';
import { IsSignInGuard } from './auth/services/is-sign-in.guard.service';
import { LoadingInterceptor } from './core/interceptors/loading.interceptor';
import { LoadingService } from './core/services/loading.service';
import { LoadingScreenComponent } from './core/loading-screen/loading-screen.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [AppComponent, LoadingScreenComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: customLoaderFactory,
      },
    }),
    HttpClientModule,
    OAuthModule.forRoot(),
    FontAwesomeModule,
    MatProgressSpinnerModule,
  ],
  providers: [
    AuthGuard,
    IsSignInGuard,
    { provide: OAuthStorage, useValue: localStorage },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    { provide: ErrorHandler, useClass: RollbarErrorHandler },
    { provide: RollbarService, useFactory: rollbarFactory },
    {
      provide: LOCALE_ID,
      useValue: 'pl-PL',
    },
    LoadingService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private translate: TranslateService) {
    registerLocaleData(localeEn, 'en');
    registerLocaleData(localePl, 'pl', localePlExtra);

    const selectedLanguage = localStorage.getItem('language');

    this.translate.addLangs(Object.values(SupportedLanguagesCodes));

    if (selectedLanguage) {
      this.setLanguage(selectedLanguage);
    } else {
      this.setLanguage(SupportedLanguagesCodes.Polish);
    }
  }

  private setLanguage(lang: string): void {
    this.translate.setDefaultLang(lang);
    this.translate.use(lang);
    moment.locale(lang);
  }
}

export function customLoaderFactory(): LanguageCustomLoader {
  return new LanguageCustomLoader();
}
