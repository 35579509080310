export const environment = {
  production: true,
  apiUrl: process.env.API_URL || 'https://api.groomoteka.kodelika.pl/v1/',

  authUrl: process.env.AUTH_URL || 'https://api.groomoteka.kodelika.pl/oauth/token',
  authClientId: process.env.AUTH_CLIENT_ID,
  authClientSecret: process.env.AUTH_CLIENT_SECRET,

  rollbarAccessToken: process.env.ROLLBAR_ACCESS_TOKEN || '',
  gitlabEnv: process.env.GITLAB_ENVIRONMENT_NAME || '',
};
