import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SupportedLanguagesCodes } from '../enums';
import * as moment from 'moment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  @Input() public layout: string;
  public currentYear: number;

  constructor(private translate: TranslateService) {}

  public ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
  }

  public currentLangIsEn(): boolean {
    return this.translate.currentLang === SupportedLanguagesCodes.English;
  }

  public currentLangIsPl(): boolean {
    return this.translate.currentLang === SupportedLanguagesCodes.Polish;
  }

  public useLanguage(language: string): void {
    this.translate.use(language);
    localStorage.setItem('language', language);
    moment.locale(language);
  }
}
