import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpResponse, HttpEvent } from '@angular/common/http';
import { LoadingService } from '../services/loading.service';
import { Observable } from 'rxjs';
import Timeout = NodeJS.Timeout;

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private requests: HttpRequest<any>[] = [];
  private timeoutEvent: Timeout;

  constructor(
    private loadingService: LoadingService
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public removeRequest(req: HttpRequest<any>): void {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }

    if (this.requests.length === 0) {
      clearTimeout(this.timeoutEvent);
      setTimeout(() => {
        this.loadingService.isLoading.next(false);
      }, 0);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.requests.push(req);

    this.timeoutEvent = setTimeout(() => {
      this.loadingService.isLoading.next(true);
      }, 0);
    return new Observable((observer) => {
      const subscription = next.handle(req).subscribe(
        (event) => {
          if (event instanceof HttpResponse) {
            this.removeRequest(req);
            observer.next(event);
          }
        },
        (err) => {
          this.removeRequest(req);
          observer.error(err);
        },
        () => {
          this.removeRequest(req);
          observer.complete();
        }
      );
      // remove request from queue when cancelled
      return () => {
        this.removeRequest(req);
        subscription.unsubscribe();
      };
    });
  }
}
